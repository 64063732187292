import React from "react"
import { graphql, PageProps } from "gatsby"
import { ParallaxProvider } from "react-scroll-parallax"
import LayoutContainer from "@containers/Layout"
import SEO from "@components/SEO"
import Hero from "@components/LoloSuccess/Hero"
import Guide from "@components/LoloSuccess/Guide"
import Cta from "@components/LoloSuccess/Cta"
import BusinessPartner from "@components/LoloSuccess/BusinessPartner"
import JoinUs from "@components/LoloSuccess/JoinUs"
import { TrainingSupportDataType } from "@typings/requests/partners/lolo-success"

const TrainingSupportPage: React.FC<PageProps<TrainingSupportDataType>> =
  props => {
    const {
      allStrapiTrainingAndSupportPage: {
        nodes: [LoloSuccessData],
      },
      allStrapiPageFooter: {
        nodes: [footerData],
      },
    } = props.data
    const {
      heroSection,
      docsSection,
      ctaSection,
      partnerSection,
      joinSection,
    } = LoloSuccessData
    return (
      <>
        <SEO
          title={LoloSuccessData.title}
          description={LoloSuccessData.description}
          pathname={props.location.pathname}
          image={LoloSuccessData.image.url}
          lang="en"
        />
        <ParallaxProvider>
          <LayoutContainer
            className="training-page"
            location={props.location}
            lang="en"
            isPartnerSubdomain
            colorLangSwitcher="black"
          >
            <Hero
              {...heroSection}
              image={
                heroSection.image.localFile.childImageSharp.gatsbyImageData
              }
            />
            <Guide {...docsSection} />
            <Cta
              {...ctaSection}
              image={ctaSection.image.localFile.childImageSharp.gatsbyImageData}
            />
            <BusinessPartner {...partnerSection} />
            <JoinUs
              {...joinSection}
              image={
                joinSection.image.localFile.childImageSharp.gatsbyImageData
              }
              footerData={footerData}
            />
          </LayoutContainer>
        </ParallaxProvider>
      </>
    )
  }

export const query = graphql`
  {
    allStrapiTrainingAndSupportPage(filter: { locale: { eq: "en" } }) {
      nodes {
        description
        title
        image {
          url
        }
        heroSection {
          heroTitle
          heroDescription
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  quality: 100
                  placeholder: BLURRED
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
        docsSection {
          title
          usefulDoc {
            name
            title
            description
            id
          }
        }
        ctaSection {
          title
          buttonLink: button {
            label
            url
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 548
                  quality: 100
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
              }
            }
          }
        }
        joinSection {
          title
          buttonLink: button {
            url
            label
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 342
                  quality: 100
                  placeholder: BLURRED
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
        partnerSection {
          title
          description
          requirementBlock {
            id
            label
            name
          }
        }
      }
    }

    allStrapiPageFooter(filter: { locale: { eq: "en" } }) {
      nodes {
        title
        socialLink {
          name
          title
          url
        }
      }
    }
  }
`

export default TrainingSupportPage
